import React from "react";
import Header from "../Component/home/Header";
const Home = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default Home;
